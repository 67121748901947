import Vue from 'vue';
import App from './App.vue';

Vue.config.productionTip = false

import router from './router';
import store from './store'
import '@/plugins/vue-anime'
import '@/plugins/vue-modal'
import '@/plugins/vue-click-outside'
import '@/plugins/vue-awesome-swiper'
import '@/plugins/vue-fullpage'
import '@/plugins/vue-social-sharing'
import '@/plugins/vue-masonry'

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
